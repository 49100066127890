import * as React from "react";
import { FormGroup } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import {
  ListOfValuesField,
  ListOfValuesFieldType,
  SchemaType,
  useGetSchemaByIdOrKey
} from "@packages/service-api";

import BaseFieldFields from "./BaseFieldFields";
import BooleanFieldFields from "./BooleanFieldFields";
import { getFieldSchemaFormResolver } from "./validation";
import FormErrors from "./FormErrors";

interface Props {
  field?: ListOfValuesField | Partial<ListOfValuesField>;
  onSubmit?: (formFields: unknown) => void;
  onClose?: () => void;
  isEditForm: boolean;
  isClone?: boolean;
}

export type SchemaFieldFormRef = {
  onSubmit: () => void;
};

type FormFields = Partial<ListOfValuesField> & {
  tagType?: "existing" | "new";
};

const getDefaultValues = (
  field?: Partial<ListOfValuesField>,
  schemaType?: SchemaType
): FormFields => {
  const defaultValues = {
    fieldKey: field?.fieldKey || "",
    label: field?.label || [{ value: "", locale: "en_us" }],
    placeholder: field?.placeholder || [{ value: "", locale: "en_us" }],
    type: field?.type || "string",
    isRequired: field?.isRequired || false,
    defaultValue:
      field?.defaultValue ?? (field?.type === "number" ? 0 : field?.type === "string" ? "" : ""),
    manualEntry: null
  } as Partial<ListOfValuesField>;

  if (defaultValues.type === "number") {
    defaultValues.decimalPlaces = field.decimalPlaces ?? 1;
  }
  if (schemaType === "manual-entry") {
    defaultValues.manualEntry = field?.manualEntry
      ? { ...field?.manualEntry, tagType: "existing" }
      : {
          units: "",
          system: "",
          tagName: "",
          reductionType: "Raw",
          reductionFrequency: 0,
          reductionOffset: "Before",
          displayLastValue: false,
          writable: true,
          expected: [],
          notAllowed: [],
          notExpected: [],
          tagType: "existing"
        };
  }

  return defaultValues;
};

const SchemaFieldForm = React.forwardRef<SchemaFieldFormRef, Props>((props, ref) => {
  const { field, onSubmit, isEditForm, isClone } = props;

  const { idOrKey } = useParams<{ idOrKey: string }>();

  const { schema } = useGetSchemaByIdOrKey(idOrKey);
  // We need to get default value only once
  const defaultValues = React.useMemo(
    () => getDefaultValues(field, schema?.schemaType),
    [field, schema]
  );

  const { handleSubmit, control, watch, setValue, formState } = useForm<Partial<ListOfValuesField>>(
    {
      defaultValues,
      resolver: getFieldSchemaFormResolver(
        field?.fieldKey as ListOfValuesFieldType,
        schema?.schemaType
      )
    }
  );

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit(onSubmit)
  }));

  return (
    <FormGroup onSubmit={handleSubmit(onSubmit)}>
      <FormErrors errors={formState.errors} />

      <BaseFieldFields
        isEditForm={isEditForm}
        control={control}
        setValue={setValue}
        watch={watch}
        isClone={isClone}
        type={field?.type}
        schemaType={schema?.schemaType}
      >
        {/* Additional fields for other field types will end up being included here */}
        {field?.type === "boolean" && <BooleanFieldFields control={control} />}
      </BaseFieldFields>
    </FormGroup>
  );
});

SchemaFieldForm.displayName = "SchemaFieldForm";

export default SchemaFieldForm;
