import { InputProps, TextField, TextFieldProps, Box, IconButton } from "@mui/material";
import { DatePicker, DatePickerProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { dayjs } from "@packages/utils";
import { Control, Controller, Path } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { ApplicationIcon } from "../icons";

export type Props<T> = Omit<DatePickerProps<Date>, "name"> & {
  name: Path<T>;
  control?: Control<T>;
  label: string;
  textFieldProps?: Omit<TextFieldProps, "label"> & {
    startAdornment?: React.ReactNode;
  };
};

function DateField<TFieldValues extends FieldValues>(props: Props<TFieldValues>): JSX.Element {
  const { name, control, label, maxDate, minDate, textFieldProps } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, onBlur }, fieldState: { invalid, error } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              minDate={minDate}
              format="MMMM dd, yyyy"
              value={dayjs(value).utc().toDate()}
              views={["year", "month", "day"]}
              maxDate={maxDate}
              onClose={onBlur}
              sx={{
                "& .MuiOutlinedInput-input": {
                  padding: "8.5px 0px 8.5px 14px"
                }
              }}
              onChange={(newValue) => {
                onChange(dayjs(newValue).utc().format("YYYY-MM-DD"));
              }}
              slots={{
                textField: (params) => (
                  <Box sx={{ display: "flex", position: "relative", alignItems: "center" }}>
                    <TextField
                      {...params}
                      sx={textFieldProps?.sx}
                      variant={textFieldProps?.variant || "standard"}
                      name={name}
                      onBlur={onBlur}
                      required={textFieldProps?.required}
                      disabled={textFieldProps?.disabled}
                      error={invalid}
                      label={label}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...((params.InputProps || {}) as Partial<InputProps>),
                        placeholder: textFieldProps?.placeholder,
                        startAdornment: textFieldProps?.startAdornment
                      }}
                      helperText={error ? error.message : textFieldProps?.helperText}
                    />
                    {!!value && (
                      <IconButton
                        sx={{ position: "absolute", right: 25, color: "text.primary" }}
                        onClick={() => onChange(null)}
                      >
                        <ApplicationIcon name="close" />
                      </IconButton>
                    )}
                  </Box>
                )
              }}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
}

export default DateField;
